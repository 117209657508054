import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVienThongBao } from 'src/app/shared/ThanhVienThongBao.model';
import { ThanhVienThongBaoService } from 'src/app/shared/ThanhVienThongBao.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-thanh-vien-thong-bao',
  templateUrl: './thanh-vien-thong-bao.component.html',
  styleUrls: ['./thanh-vien-thong-bao.component.css']
})
export class ThanhVienThongBaoComponent implements OnInit {

  @ViewChild('ThanhVienThongBaoSort') ThanhVienThongBaoSort: MatSort;
  @ViewChild('ThanhVienThongBaoPaginator') ThanhVienThongBaoPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienThongBaoService: ThanhVienThongBaoService,

    public ThanhVienService: ThanhVienService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungSearch();
    this.ThanhVienSearch();
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetByIDToListAsync(this.ThanhVienThongBaoService);
  }
  ThanhVienSearch() {
    this.ThanhVienService.ComponentGetAllToListAsync(this.ThanhVienThongBaoService);
  }

  ThanhVienThongBaoSearch() {
    this.ThanhVienThongBaoService.SearchByDanhMucUngDungID(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator);
  }
  ThanhVienThongBaoSave(element: ThanhVienThongBao) {
    this.ThanhVienThongBaoService.FormData = element;
    this.NotificationService.warn(this.ThanhVienThongBaoService.ComponentSaveByDanhMucUngDungID(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator));
  }
  ThanhVienThongBaoDelete(element: ThanhVienThongBao) {
    this.ThanhVienThongBaoService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.ThanhVienThongBaoService.ComponentDeleteByDanhMucUngDungID(this.ThanhVienThongBaoSort, this.ThanhVienThongBaoPaginator));
  }
}