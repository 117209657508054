<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-LoHang" data-bs-toggle="tab" href="#LoHang"
                                    role="tab" aria-controls="LoHang" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Truy xuất nguồn gốc
                                        (TXNG)</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="LoHang" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Ứng dụng</label>
                                        <select class="form-select" name="DanhMucUngDungID"
                                            [(ngModel)]="LoHangService.BaseParameter.DanhMucUngDungID">
                                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Bắt đầu</label>
                                        <input [ngModel]="LoHangService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateBatDau($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Kết thúc</label>
                                        <input [ngModel]="LoHangService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                            style="text-align: right;" (ngModelChange)="DateKetThuc($event)"
                                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                            autocomplete="off">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="LoHangService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="LoHangSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHangSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                            (click)="LoHangAdd(0)"><i class="bi bi-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="LoHangService.List">({{LoHangService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHangSort="matSort" [dataSource]="LoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quản lý
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="LoHangAdd(element.ID)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-people"></i> Hồ sơ
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="LoHangCopy(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-subtract"></i> Copy
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="width: 100%;" class="btn btn-danger"
                                                                        (click)="LoHangDelete(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-trash"></i> Xóa
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>ID:&nbsp;</label>
                                                                    <label class="form-label">{{element.ID}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Code:&nbsp;</label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a style="cursor: pointer;"
                                                                        title="{{element.ParentName}}"
                                                                        class="link-primary"
                                                                        (click)="ToChucAdd(element.ParentID)"><b>{{element.ParentName}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Điện thoại:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DienThoai}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã số thuế:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.MaSoThue}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lô hàng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã hồ sơ:&nbsp;</label>
                                                                    <label class="form-label">{{element.MaHoSo}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Lô hàng:&nbsp;</label>
                                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="LoHangAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã lô đóng gói hàng hóa:&nbsp;</label>                                                                    
                                                                    <a style="cursor: pointer;"
                                                                        title="{{element.MaLoHang}}"
                                                                        class="link-primary"
                                                                        (click)="LoHangAdd(element.ID)"><b>{{element.MaLoHang}}</b></a>
                                                                </div>                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Sản phẩm:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucSanPhamName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Loại hình:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucLoaiHinhName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Phương thức:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucPhuongThucSanXuatName}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DangKy">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Đăng ký:&nbsp;</label>
                                                                    <label class="form-label"
                                                                        *ngIf="element.DangKy">{{element.DangKy |
                                                                        date:'dd/MM/yyyy'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Công bố:&nbsp;</label>
                                                                    <label class="form-label"
                                                                        *ngIf="element.CongBo">{{element.CongBo |
                                                                        date:'dd/MM/yyyy'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Phê duyệt:&nbsp;</label>
                                                                    <label class="form-label"
                                                                        *ngIf="element.PheDuyet">{{element.PheDuyet |
                                                                        date:'dd/MM/yyyy'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label"
                                                                            for="Active{{element.ID}}">&nbsp;Đăng
                                                                            ký</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsKiemTra{{element.ID}}"
                                                                            [(ngModel)]="element.IsKiemTra">
                                                                        <label class="form-label"
                                                                            for="IsKiemTra{{element.ID}}">&nbsp;Kiểm tra</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsPheDuyet{{element.ID}}"
                                                                            [(ngModel)]="element.IsPheDuyet">
                                                                        <label class="form-label"
                                                                            for="IsPheDuyet{{element.ID}}">&nbsp;Phê
                                                                            duyệt</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHangSort="matSort" [dataSource]="LoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã hồ sơ:&nbsp;</label>
                                                                    <label class="form-label">{{element.MaHoSo}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Lô hàng:&nbsp;</label>
                                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                                        class="link-primary"
                                                                        (click)="LoHangAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã lô hàng:&nbsp;</label>
                                                                    <a style="cursor: pointer;"
                                                                        title="{{element.MaLoHang}}"
                                                                        class="link-primary"
                                                                        (click)="LoHangAdd(element.ID)"><b>{{element.MaLoHang}}</b></a>
                                                                </div>                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Sản phẩm:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucSanPhamName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Loại hình:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucLoaiHinhName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Phương thức:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.DanhMucPhuongThucSanXuatName}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="row gx-4">
                                                                <div class="col-lg-4 col-sm-4 col-4">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="LoHangAdd(element.ID)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-people"></i> Hồ sơ
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-4 col-sm-4 col-4">
                                                                    <a style="width: 100%;" class="btn btn-success"
                                                                        (click)="LoHangCopy(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-subtract"></i> Copy
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-4 col-sm-4 col-4">
                                                                    <a style="width: 100%;" class="btn btn-danger"
                                                                        (click)="LoHangDelete(element)"
                                                                        *ngIf="element.ID > 0"><i
                                                                            class="bi bi-trash"></i> Xóa
                                                                    </a>
                                                                </div>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>