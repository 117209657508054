import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';

@Component({
  selector: 'app-danh-muc-ung-dung',
  templateUrl: './danh-muc-ung-dung.component.html',
  styleUrls: ['./danh-muc-ung-dung.component.css']
})
export class DanhMucUngDungComponent implements OnInit {

  @ViewChild('DanhMucUngDungSort') DanhMucUngDungSort: MatSort;
  @ViewChild('DanhMucUngDungPaginator') DanhMucUngDungPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.DanhMucUngDungService.FileToUpload = files;
    }
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.IsShowLoading = true;
    this.DanhMucUngDungService.BaseParameter.ID = environment.DanhMucUngDungID;
    this.DanhMucUngDungService.GetByIDToListAsync().subscribe(
      res => {
        this.DanhMucUngDungService.List = (res as DanhMucUngDung[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucUngDungService.ListFilter = this.DanhMucUngDungService.List.filter(item => item.ID > 0);
        this.DanhMucUngDungService.DataSource = new MatTableDataSource(this.DanhMucUngDungService.List);
        this.DanhMucUngDungService.DataSource.sort = this.DanhMucUngDungSort;
        this.DanhMucUngDungService.DataSource.paginator = this.DanhMucUngDungPaginator;
        this.DanhMucUngDungService.IsShowLoading = false;
      },
      err => {
        this.DanhMucUngDungService.IsShowLoading = false;
      }
    );
  }
  DanhMucUngDungSave(element: DanhMucUngDung) {
    this.DanhMucUngDungService.FormData = element;
    this.DanhMucUngDungService.IsShowLoading = true;
    this.DanhMucUngDungService.SaveAndUploadFileAsync().subscribe(
      res => {
        element = res as DanhMucUngDung;
        this.DanhMucUngDungSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucUngDungService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucUngDungService.IsShowLoading = false;
      }
    );
  }
  DanhMucUngDungDelete(element: DanhMucUngDung) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucUngDungService.IsShowLoading = true;
      this.DanhMucUngDungService.BaseParameter.ID = element.ID;
      this.DanhMucUngDungService.RemoveAsync().subscribe(
        res => {
          element = res as DanhMucUngDung;
          this.DanhMucUngDungSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.DanhMucUngDungService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.DanhMucUngDungService.IsShowLoading = false;
        }
      );
    }
  }
}