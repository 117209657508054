import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangPhanAnh } from 'src/app/shared/LoHangPhanAnh.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangPhanAnhService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'LastUpdatedDate', 'Code', 'Description', 'HoTen', 'DienThoai', 'Email', 'DonViCongTac', 'DanhMucQuocGiaName', 'DanhMucTinhThanhName', 'IPAddress', 'KinhDo', 'ViDo' ];

    List: LoHangPhanAnh[] | undefined;
    ListFilter: LoHangPhanAnh[] | undefined;
    FormData!: LoHangPhanAnh;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangPhanAnh";
    }

    GetSQLByLoHangIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByLoHangIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

