import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';
import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { LoHangTapTinDinhKemService } from 'src/app/shared/LoHangTapTinDinhKem.service';
import { LoHangNhatKy } from 'src/app/shared/LoHangNhatKy.model';
import { LoHangNhatKyService } from 'src/app/shared/LoHangNhatKy.service';
import { LoHangLoTrinh } from 'src/app/shared/LoHangLoTrinh.model';
import { LoHangLoTrinhService } from 'src/app/shared/LoHangLoTrinh.service';
import { LoHangDiemBan } from 'src/app/shared/LoHangDiemBan.model';
import { LoHangDiemBanService } from 'src/app/shared/LoHangDiemBan.service';
import { LoHangBinhChon } from 'src/app/shared/LoHangBinhChon.model';
import { LoHangBinhChonService } from 'src/app/shared/LoHangBinhChon.service';
import { LoHangPhanAnh } from 'src/app/shared/LoHangPhanAnh.model';
import { LoHangPhanAnhService } from 'src/app/shared/LoHangPhanAnh.service';



import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-lo-hang-map',
  templateUrl: './lo-hang-map.component.html',
  styleUrls: ['./lo-hang-map.component.css']
})
export class LoHangMapComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoHangMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public LoHangTapTinDinhKemService: LoHangTapTinDinhKemService,
    public LoHangNhatKyService: LoHangNhatKyService,
    public LoHangDiemBanService: LoHangDiemBanService,
    public LoHangLoTrinhService: LoHangLoTrinhService,
    public LoHangBinhChonService: LoHangBinhChonService,
    public LoHangPhanAnhService: LoHangPhanAnhService,

  ) { }

  ngOnInit(): void {
    this.LoHangSearch();
  }
  LoHangSearch() {
    this.LoHangService.BaseParameter.ID = this.LoHangService.FormData.ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;       
      },
      err => {
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = 12;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad(ID: number) {

    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = this.LoHangService.FormData.ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;

        if (this.LoHangService.FormData) {
          let latitude = environment.Latitude;
          let longitude = environment.Longitude;

          if (this.LoHangService.FormData.VungNguyenLieuKinhDo) {
            if (this.LoHangService.FormData.VungNguyenLieuViDo) {
              latitude = this.DownloadService.GetViDo(Number(this.LoHangService.FormData.VungNguyenLieuViDo));
              longitude = this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.VungNguyenLieuKinhDo));
            }
          }         
          this.MapInitialization(longitude, latitude);
          if ((ID == 0) || (ID == 4)) {
            if (this.LoHangService.FormData.VungNguyenLieuKinhDo) {
              if (this.LoHangService.FormData.VungNguyenLieuViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.VungNguyenLieuMaSoVungTrong + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';               
                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.VungNguyenLieuKinhDo)), this.DownloadService.GetViDo(Number(this.LoHangService.FormData.VungNguyenLieuViDo))])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
          }
          if ((ID == 0) || (ID == 2)) {
            if (this.LoHangService.FormData.NhaMayCheBienKinhDo) {
              if (this.LoHangService.FormData.NhaMayCheBienViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.NhaMayCheBienName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })                  
                  .setLngLat([this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.NhaMayCheBienKinhDo)), this.DownloadService.GetViDo(Number(this.LoHangService.FormData.NhaMayCheBienViDo))])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.CoSoDongGoiKinhDo) {
              if (this.LoHangService.FormData.CoSoDongGoiViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.CoSoDongGoiName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })                  
                  .setLngLat([this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.CoSoDongGoiKinhDo)), this.DownloadService.GetViDo(Number(this.LoHangService.FormData.CoSoDongGoiViDo))])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.KhoLuuTruKinhDo) {
              if (this.LoHangService.FormData.KhoLuuTruViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.KhoLuuTruName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })                  
                  .setLngLat([this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.KhoLuuTruKinhDo)), this.DownloadService.GetViDo(Number(this.LoHangService.FormData.KhoLuuTruViDo))])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
            if (this.LoHangService.FormData.KhoThuongMaiKinhDo) {
              if (this.LoHangService.FormData.KhoThuongMaiViDo) {
                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<h1>" + this.LoHangService.FormData.KhoThuongMaiName + "</h1>";
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })                  
                  .setLngLat([this.DownloadService.GetKinhDo(Number(this.LoHangService.FormData.KhoThuongMaiKinhDo)), this.DownloadService.GetViDo(Number(this.LoHangService.FormData.KhoThuongMaiViDo))])
                  .setPopup(popup)
                  .addTo(this.map);
              }
            }
          }
          if ((ID == 0) || (ID == 1)) {
            this.LoHangLoTrinhService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
            this.LoHangLoTrinhService.GetByParentIDToListAsync().subscribe(
              res => {
                this.LoHangLoTrinhService.List = res as LoHangLoTrinh[];
                for (let i = 0; i < this.LoHangLoTrinhService.List.length; i++) {
                  let LoHangLoTrinh = this.LoHangLoTrinhService.List[i];
                  if (LoHangLoTrinh.KinhDo) {
                    if (LoHangLoTrinh.ViDo) {
                      let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                      popupContent = popupContent + "<h1>" + LoHangLoTrinh.Name + "</h1>";
                      popupContent = popupContent + "</div>";

                      let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                        .setMaxWidth("600px");

                      var el = document.createElement('div');
                      el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                      el.style.width = '30px';
                      el.style.height = '30px';

                      let marker = new maplibregl.Marker({ element: el })                        
                        .setLngLat([this.DownloadService.GetKinhDo(Number(LoHangLoTrinh.KinhDo)), this.DownloadService.GetViDo(Number(LoHangLoTrinh.ViDo))])
                        .setPopup(popup)
                        .addTo(this.map);
                    }
                  }
                }
              },
              err => {
              }
            );
          }
          if ((ID == 0) || (ID == 3)) {
            this.LoHangDiemBanService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
            this.LoHangDiemBanService.GetByParentIDToListAsync().subscribe(
              res => {
                this.LoHangDiemBanService.List = res as LoHangDiemBan[];
                for (let i = 0; i < this.LoHangDiemBanService.List.length; i++) {
                  let LoHangDiemBan = this.LoHangDiemBanService.List[i];
                  if (LoHangDiemBan.KinhDo) {
                    if (LoHangDiemBan.ViDo) {
                      let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                      popupContent = popupContent + "<h1>" + LoHangDiemBan.Name + "</h1>";
                      popupContent = popupContent + "</div>";

                      let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                        .setMaxWidth("600px");

                      var el = document.createElement('div');
                      el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo30.png)";
                      el.style.width = '30px';
                      el.style.height = '30px';

                      let marker = new maplibregl.Marker({ element: el })                        
                        .setLngLat([this.DownloadService.GetKinhDo(Number(LoHangDiemBan.KinhDo)), this.DownloadService.GetViDo(Number(LoHangDiemBan.ViDo))])
                        .setPopup(popup)
                        .addTo(this.map);
                    }
                  }
                }
              },
              err => {
              }
            );
          }
        }
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
}

