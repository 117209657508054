import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class LoHangTapTinDinhKemService extends BaseService{

    DisplayColumns001: string[] = ['DanhMucTapTinDinhKemPhanLoaiID', 'NgayCap', 'Save'];
    DisplayColumns002: string[] = ['DanhMucTapTinDinhKemPhanLoaiID', 'NgayCap'];
        
    List: LoHangTapTinDinhKem[] | undefined;
    ListFilter: LoHangTapTinDinhKem[] | undefined;
    FormData!: LoHangTapTinDinhKem;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "LoHangTapTinDinhKem";
    }
}

