<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Đơn vị</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ToChucService.FormData.Active" disabled />
                                            <label class="form-check-label" for="Active">Kích hoạt
                                                [{{ToChucService.FormData.ID}}]</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ToChucService.FormData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Code</label>
                                            <input name="Code" [(ngModel)]="ToChucService.FormData.Code"
                                                placeholder="Code" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế</label>
                                            <input name="MaSoThue" [(ngModel)]="ToChucService.FormData.MaSoThue"
                                                placeholder="Mã số thuế" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ToChucService.FormData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ToChucService.FormData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Website</label>
                                            <input name="Website" [(ngModel)]="ToChucService.FormData.Website"
                                                placeholder="Website" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ToChucService.FormData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phân loại
                                                [{{ToChucService.FormData.ParentID}}]</label>
                                            <select class="form-select" name="ParentID"
                                                [(ngModel)]="ToChucService.FormData.ParentID">
                                                <option *ngFor="let item of DanhMucToChucService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ứng dụng
                                                [{{ToChucService.FormData.DanhMucUngDungID}}]</label>
                                            <select class="form-select" name="DanhMucUngDungID"
                                                [(ngModel)]="ToChucService.FormData.DanhMucUngDungID">
                                                <option *ngFor="let item of DanhMucUngDungService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đại diện</label>
                                            <input name="LienHeHoTen" [(ngModel)]="ToChucService.FormData.LienHeHoTen"
                                                placeholder="Đại diện" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chức danh</label>
                                            <input name="LienHeChucDanh"
                                                [(ngModel)]="ToChucService.FormData.LienHeChucDanh"
                                                placeholder="Chức danh" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="LienHeDienThoai"
                                                [(ngModel)]="ToChucService.FormData.LienHeDienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="LienHeEmail" [(ngModel)]="ToChucService.FormData.LienHeEmail"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ToChucService.FormData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>                                        
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quốc gia
                                                [{{ToChucService.FormData.DanhMucQuocGiaID}}]</label>
                                            <select class="form-select" name="DanhMucQuocGiaID"
                                                [(ngModel)]="ToChucService.FormData.DanhMucQuocGiaID"
                                                (change)="DanhMucTinhThanhSearch()">
                                                <option *ngFor="let item of DanhMucQuocGiaService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành
                                                [{{ToChucService.FormData.DanhMucTinhThanhID}}]</label>
                                            <select class="form-select" name="DanhMucTinhThanhID"
                                                [(ngModel)]="ToChucService.FormData.DanhMucTinhThanhID" (change)="DanhMucQuanHuyenSearch()">
                                                <option *ngFor="let item of DanhMucTinhThanhService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện
                                                [{{ToChucService.FormData.DanhMucQuanHuyenID}}]</label>
                                            <select class="form-select" name="DanhMucQuanHuyenID"
                                                [(ngModel)]="ToChucService.FormData.DanhMucQuanHuyenID"
                                                (change)="DanhMucXaPhuongSearch()">
                                                <option *ngFor="let item of DanhMucQuanHuyenService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường
                                                [{{ToChucService.FormData.DanhMucXaPhuongID}}]</label>
                                            <select class="form-select" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ToChucService.FormData.DanhMucXaPhuongID">
                                                <option *ngFor="let item of DanhMucXaPhuongService.List;"
                                                    [value]="item.ID">
                                                    {{item.Note}} {{item.Name}} [{{item.ID}}]</option>
                                            </select>
                                        </div>                                       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kho tem</label>
                                            <input disabled name="TemPhieuSoLuong" [(ngModel)]="ToChucService.FormData.TemPhieuSoLuong" placeholder="0" type="number"
                                                class="form-control" style="text-align: right; font-weight: bold; color: #000000;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sử dụng</label>
                                            <input disabled name="TemPhieuSuDung" [(ngModel)]="ToChucService.FormData.TemPhieuSuDung" placeholder="0" type="number"
                                                class="form-control" style="text-align: right; font-weight: bold; color: red;">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Còn lại</label>
                                            <input disabled name="TemPhieuConLai" [(ngModel)]="ToChucService.FormData.TemPhieuConLai" placeholder="0" type="number"
                                                class="form-control" style="text-align: right; font-weight: bold; color: green;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ToChucService.IsShowLoading"></app-loading>