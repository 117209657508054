import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-to-chuc',
  templateUrl: './to-chuc.component.html',
  styleUrls: ['./to-chuc.component.css']
})
export class ToChucComponent implements OnInit {

  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungSearch();
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetByIDToListAsync(this.ToChucService);
  }
  ToChucSearch() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.GetByDanhMucUngDungID_SearchStringToListAsync().subscribe(
      res => {
        this.ToChucService.List = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ToChucService.DataSource = new MatTableDataSource(this.ToChucService.List);
        this.ToChucService.DataSource.sort = this.ToChucSort;
        this.ToChucService.DataSource.paginator = this.ToChucPaginator;
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ToChucSearch();
        });
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
}