import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TemPhieuKhoiTaoChiTietService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'MaSo', 'URL', 'Active'];
    DisplayColumns002: string[] = ['STT', 'ID', 'Code', 'MaSo', 'URL', 'Active'];
        
    List: TemPhieuKhoiTaoChiTiet[] | undefined;
    ListFilter: TemPhieuKhoiTaoChiTiet[] | undefined;
    FormData!: TemPhieuKhoiTaoChiTiet;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TemPhieuKhoiTaoChiTiet";
    }
    SaveListActiveAsync(list: TemPhieuKhoiTaoChiTiet[]) {
        let url = this.APIURL + this.Controller + '/SaveListActiveAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetSQLByToChucID_ActiveToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByToChucID_ActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetSQLByToChucID_Active_SearchStringToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByToChucID_Active_SearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

