import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TemPhieuLichSuTruyCap } from 'src/app/shared/TemPhieuLichSuTruyCap.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class TemPhieuLichSuTruyCapService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'Code', 'Name', 'LastUpdatedDate'];
    DisplayColumns002: string[] = ['STT', 'LastUpdatedDate', 'Name', 'TypeName', 'IPAddress', 'DanhMucQuocGiaName', 'DanhMucTinhThanhName', 'KinhDo', 'ViDo'];

    List: TemPhieuLichSuTruyCap[] | undefined;
    ListFilter: TemPhieuLichSuTruyCap[] | undefined;
    FormData!: TemPhieuLichSuTruyCap;


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "TemPhieuLichSuTruyCap";
    }

    GetSQLByLoHangIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetSQLByLoHangIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

